import AuthService from '../services/auth.service';
import agent from "@/agent"

const token = JSON.parse(localStorage.getItem('token'));
const lang = JSON.parse(localStorage.getItem('lang'));
import router from '@/router'

const initialState = {
    main_url: process.env.VUE_APP_ENV_MAIN_URL.replace(/\/$/, "") || 'http://127.0.0.1:8000/'.replace(/\/$/, ""),
    status: {loggedIn: !!token, emailSent: false},
    token: token ? token : null,
    user: {
        first_name: '',
        last_name: '',
        full_name: '',
        lang: lang ? lang : {code: 'nl', name: 'Nederlands'},
        id: null,
        photo: null,
        relations: [
            {
                id: null,
                name: '',
                is_child: false,
                addresses: [],
                invoice_debtor: null
            }
        ]
    },
    available_langs: [
        {
            code: 'nl',
            name: 'Nederlands'
        }, {
            code: 'us',
            name: 'English'
        }, {
            code: 'fr',
            name: 'Français'
        }
    ]
}
export const auth = {
    namespaced: true,
    state: initialState,
    getters: {
        user: state => {
            return state.user;
        },
        available_langs: state => {
            return state.available_langs;
        },
        main_url: state => {
            return state.main_url;
        },
    },
    actions: {
        login({commit}, user) {
            return AuthService.login(user).then((response) => {
                commit('emailSentSuccess');
                return Promise.resolve(response);
              }
                // user => {
                //     commit('loginSuccess', user);
                //     return Promise.resolve(user);
                // },
                // error => {
                //     commit('loginFailure');
                //     return Promise.reject(error);
                // }
            );
        },
        logout({commit}) {
            AuthService.logout();
            commit('logout');
        },
        register({commit}, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        },
        password_reset({commit}, user) {
            return AuthService.password_reset(user).then(
                response => {
                    commit('passwordResetSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('passwordResetFailure');
                    return Promise.reject(error);
                }
            );
        },
        set_password({commit}, data) {
            return AuthService.set_password(data).then(
                response => {
                    commit('passwordResetSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('passwordResetFailure');
                    return Promise.reject(error);
                }
            );
        },
        setUser({commit}) {
            return agent.UserService.getCurrentUser().then(function (response) {
                commit('setUser', response.data)
                return Promise.resolve(response.data);
            }).catch(error => {
                commit('setUserFailure')
                return Promise.reject(error);
            });
        },
        setUserLang({commit}, lang) {
            commit('setUserLang', lang)
        },
        confirmEmail({commit}, params){
            return AuthService.confirmEmail(params).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, token) {
            state.status.loggedIn = true;
            state.token = token;
            localStorage.removeItem("relations");
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.token = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.token = null;
            state.user = initialState.user;
            localStorage.removeItem("relations");
            router.push('/login')
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        passwordResetSuccess(state) {
            state.status.loggedIn = false;
        },
        passwordResetFailure(state) {
            state.status.loggedIn = false;
        },
        setUser(state, user) {
            user.lang = state.user.lang
            state.user = user;
        },
        setUserLang(state, lang) {
            if (state.user.lang !== lang) {
                state.user.lang = lang;
                localStorage.setItem('lang', JSON.stringify(lang));
            }
        },
        setUserFailure(state) {
            state.status.loggedIn = false;
            state.token = null;
            state.user = initialState.user;
            router.push('/login')
        },
        emailSentSuccess(state){
            state.status.emailSent = true;
        }
    }
};