<template>
    <div class="row">
        <div class="col-md-12">
            <loading :active="isLoading" :can-cancel="false" :is-full-page="false"></loading>
            <div class="card">
                <div class="card-body">
                    <DataTable :totalRecords="totalRecords" :lazy="true" :value="orders" :paginator="true"
                               :rows="10" v-model:filters="filters1"
                               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                               :rowsPerPageOptions="[5,10,20,50]" responsiveLayout="scroll" dataKey="id" ref="dt"
                               @page="onPage($event)" @sort="onSort($event)" @filter="onFilter($event)"
                               :currentPageReportTemplate="$__('Showing {first} to {last} of {totalRecords}')"
                               :globalFilterFields="['order_number']"
                    >
                        <template #header>
                            <div class="d-flex">
                 <span class="p-input-icon-left ml-auto">
                      <i class="pi pi-search"/>
                      <InputText @keydown.enter="filterCallback()" v-model="filters1['global'].value"
                                 :placeholder="$__('Global Search')" :style="`--bcolor: ${$env_color}`"/>
                  </span>
                            </div>
                        </template>
                        <Column field="order_number" :header="$__('Order')" :sortable="true"></Column>
                        <Column field="created_at" :header="$__('Created')"></Column>
                        <Column field="closed_at" :header="$__('Collected')"></Column>
                        <Column field="disposer" :header="$__('Disposer')"></Column>
                        <Column field="origin" :header="$__('Origin')"></Column>
                        <Column field="waste_group" :header="$__('Waste Group')">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.order_type === 'collection'" v-tooltip.top="$__(slotProps.data.waste_group)">
                                    {{ $__(slotProps.data.waste_group) }}
                                </div>
                                <div v-else v-tooltip.top="slotProps.data.cargo.map(c => $(c.waste_group.name)).join(', ')">
                                    {{slotProps.data.cargo.slice(0, 1).map(c => $__(c.waste_group.name)).join(', ')}} {{slotProps.data.cargo.length > 1 ? '+' + slotProps.data.cargo.slice(1).length: ''}}
                                </div>
                            </template>
                        </Column>
                        <Column field="eural_code" :header="$__('Eural Code')">
                            <template #body="slotProps">
                                <div v-if="slotProps.data.order_type === 'collection'" v-tooltip.top="slotProps.data.eural_code">
                                    {{ slotProps.data.eural_code }}
                                </div>
                                <div v-else v-tooltip.top="slotProps.data.cargo.map(c => c.eural_code.code).join(', ')">
                                    {{slotProps.data.cargo.slice(0, 3).map(c => c.eural_code.code).join(', ')}} {{slotProps.data.cargo.length > 3 ? '+' + slotProps.data.cargo.slice(3).length: ''}}
                                </div>
                            </template>
                        </Column>
                        <Column field="collected_weight_kg" :header="$__('Weight')"></Column>
                        <Column field="status" :header="$__('Status')" :sortable="true" filterMatchMode="equals">
                            <template #body="slotProps">
                                <span class="badge"
                                      :class="slotProps.data.status.key === 4 ? 'badge-success' : slotProps.data.status.key === 1 ? 'badge-primary' : 'badge-warning'">{{ slotProps.data.status.value }}</span>
                            </template>
                        </Column>
                        <Column :header="$__('Actions')" :sortable="false">
                            <template #body="slotProps">
                                <Button icon="pi pi-download" class="p-button-rounded p-button-secondary" @click="downloadTransportLetter(slotProps.data)" />
                            </template>
                        </Column>
                        <template #empty>
                            {{ $__('No orders found.') }}
                        </template>
                        <template #paginatorLeft>
                            <Button @click="refreshLastOrders" type="button" icon="pi pi-refresh"
                                    class="p-button-text"/>
                        </template>
                        <template #paginatorRight>
                            <Button type="button" icon="pi pi-cloud" class="p-button-text" v-show="false"/>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import {ref} from 'vue';
import InputText from 'primevue/inputtext';
import agent from "@/agent";

require('primevue/resources/themes/saga-blue/theme.css')
require('primevue/resources/primevue.min.css')
require('primeicons/primeicons.css')
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {envColor} from "../../mixins";
import Tooltip from 'primevue/tooltip';


export default {
    name: "CollectionOrdersPage",
    mixins: [envColor],
    setup() {
        const search = ''
        const totalRecords = ref(0);
        const isLoading = ref(false);
        const filters1 = ref({'global': {value: null, matchMode: 'icontains'}});
        const orders = ref([]);
        const lazyParams = ref({});
        return {search, isLoading, filters1, orders, totalRecords, lazyParams};
    },
    components: {
        DataTable, Column, Button, InputText, Loading
    },
    directives: {
        'tooltip': Tooltip
    },
    watch: {
        'selectedRelations': {
            handler: function () {
                let self = this;
                self.getLastOrders()
            },
            deep: true
        },
        'authUserLang': {
            handler: function (newVal) {
                if (newVal){
                    this.getLastOrders()
                }
            }
        },
    },
    computed: {
        ...mapState({
            selectedRelations: state => state.orders.selectedRelations,
            authUserLang: state => state.auth.user.lang
        }),
    },
    created() {
    },
    mounted() {
        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters1
        };
        this.getLastOrders()
    },
    methods: {
        getLastOrders() {
            let self = this;
            this.isLoading = true

            let ids = this.selectedRelations.map(function(item) {
                return item['id'];
            });

            agent.Orders.all(ids, {lazyEvent: this.lazyParams}).then(response => {
                self.orders = response.data['orders']
                self.totalRecords = response.data['totalRecords']
                self.isLoading = false
            })
        },
        refreshLastOrders() {
            let self = this;
            this.isLoading = true

            let ids = this.selectedRelations.map(function(item) {
                return item['id'];
            });

            agent.Orders.all(ids, {lazyEvent: this.lazyParams}).then(response => {
                self.orders = response.data['orders']
                self.totalRecords = response.data['totalRecords']
                self.isLoading = false
            })
        },
        onPage(event) {
            this.lazyParams = event;
            this.getLastOrders();
        },
        onSort(event) {
            this.lazyParams = event;
            this.getLastOrders();
        },
        onFilter() {
            this.lazyParams.filters = this.filters1;
            this.getLastOrders();
        },
        filterCallback() {
            this.lazyParams.filters = this.filters1;
            this.getLastOrders();
        },
        downloadTransportLetter(order){
            let self = this;
            agent.Orders.getTransportLetter(order.id, order.order_type).then(response => {
                let custom_type = '.pdf';
                if ('x-type' in response.headers){
                    custom_type = response.headers['x-type'];
                }
                let blob = new Blob([response.data], { type: response.data.type })
                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = order.order_number + '-' + self.$__('transport-letter') + custom_type
                link.click()
            }).catch(error => {
                this.$toast.add({
                    severity: 'error',
                    summary: error.response.statusText,
                    detail: error.response.data.detail,
                    life: 5000
                });
                this.isLoading = false
            })
        }
    },
}
</script>

<style scoped>

</style>