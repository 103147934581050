<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <a href="" class="brand-logo">
                <img id="logo_img" v-cloak/>
            </a>
            <div class="card mb-0">
                <div class="card-body">
                    <template v-if="isMsgSent">
                        <h4 class="card-title mb-1">{{ $__('Login') }}</h4>
                        <lang-chooser></lang-chooser>
                        <span v-html="msgText"></span>&nbsp;
                        <router-link to="/register">
                            <span :style="{color: $env_color}">{{ $__('register') }}</span>
                        </router-link>
                    </template>
                    <template v-else>
                        <template v-if="isTokenExpired">
                            <h4 class="card-title mb-1 text-center">{{ $__('Link expired') }}</h4>
                            <lang-chooser></lang-chooser>
                            <span>{{$__('Unfortunately, the link has expired.')}}</span>
                            <br/>
                            <span>{{$__('Please retrieve a new link by pressing the sign in button once more.')}}</span>
                        </template>
                        <template v-else>
                            <h4 class="card-title mb-1 text-center">{{ $__('Welcome to our customer portal') }}</h4>
                            <lang-chooser></lang-chooser>
                            <p class="card-text mb-2 text-center">{{ $__('Please sign in below to access your data.') }}</p>
                        </template>
                        <Form @submit="handleLogin" :validation-schema="schema" class="auth-login-form mt-2" method="POST">
                            <div class="form-group">
                                <label for="login-email" class="form-label">{{ $__('Email') }}</label>
                                <Field type="text" class="form-control" id="login-email" @focus="focus" @blur="blur"
                                       name="email" placeholder="john@example.com" aria-describedby="login-email"
                                       tabindex="1" autofocus/>
                                <ErrorMessage name="email" class="error-feedback"/>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block" tabindex="4" :disabled="loading"
                                    :style="{background: $env_color, borderColor: $env_color}">
                                <span v-show="loading" class="spinner-border spinner-border-sm"></span> {{ $__('Sign in') }}
                            </button>
                            <div class="form-group" v-if="!isTokenExpired">
                                <div v-if="message" class="alert alert-danger" role="alert">
                                    {{ message }}
                                </div>
                            </div>
                        </form>
                    </template>
                    <p class="text-center mt-2">
                        <span>{{ $__('New on our portal?') }} </span>
                        <router-link to="/register">
                            <span :style="{color: $env_color}">{{ $__('Create an account') }}</span>
                        </router-link>
                    </p>
                </div>
            </div>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import {Form, Field, ErrorMessage} from "vee-validate";
import * as yup from "yup";
import {envColor} from "../mixins";
import langChooser from "./utils/langChooser.vue";

const feather = require('feather-icons')

export default {
    name: "Login",
    mixins: [envColor],
    components: {
        Form,
        Field,
        ErrorMessage,
        langChooser
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().email().required("Email is required!").strict().lowercase(),
        });

        return {
            loading: false,
            message: "",
            schema,
            isMsgSent: false,
            isTokenExpired: false
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        msgText() {
            return this.$__('If your e-mail address is known in our system, you will receive an e-mail with a link and instructions on how to login. <br/><br/>If you do not receive an e-mail, you probably need to')
        }
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        } else {
            const query = this.$route.query;
            if(query && query.token && query.uid && query.scope === 'portal'){
                this.$store.dispatch("auth/confirmEmail", query).then(
                    async () => {
                        await this.$store.dispatch("auth/setUser");
                        this.$router.push("/");
                    },
                    (error) => {
                        if(error.response && error.response.status === 403){
                            this.isTokenExpired = true;
                        } else {
                            this.message = error.response.data.message;
                        }
                    }
                );
            }
        }
    },
    mounted() {
        feather.replace()
        document.body.classList.add('blank-page');
        document.body.classList.add('login-page');
        const pathToBg = require(`@/assets/images/${this.$background_image}`)
        const pathToLogo = require(`@/assets/images/${this.$logo}`);
        document.body.style.backgroundImage = `url('${pathToBg}')`;
        let logo_img = document.getElementById('logo_img')
        logo_img.style.height = `100px`;
        logo_img.src = `${pathToLogo}`;
        let footer = document.getElementById('footer')
        footer.classList.add('footer_login')
    },
    unmounted() {
        document.body.classList.remove('blank-page');
        document.body.classList.remove('login-page');
        document.body.style.backgroundImage = 'none';
        let footer = document.getElementById('footer')
        footer.classList.remove('footer_login')
    },
    methods: {
        handleLogin(user) {
            this.loading = true;
            this.$store.dispatch("auth/login", user).then(
                (response) => {
                    this.loading = false;
                    this.message = response.message;
                    this.isMsgSent = true;
                },
                (error) => {
                    this.loading = false;
                    let message = ''
                    if(error.response && error.response.data && error.response.data.code && error.response.data.code === '1') {
                        this.isMsgSent = true;
                    }
                    if(error.response && error.response.data && error.response.data.message){
                        message = error.response.data.message
                    } else if (error.response && error.response.data && error.response.data.detail){
                        message = error.response.data.detail
                    } else {
                        message = error.message || error.toString()
                    }
                    this.message = message;
                }
            );
        },
    },
};
</script>

<style scoped>
</style>