<template>
    <div class="card card-shadow flex-fill">
        <img class="card-img-top card-img-fix" :src="order_image" alt="Card image cap" height="200">
        <div class="card-body">
            <div class="row h-100">
                <div class="col-12">
                    <h4 class="card-title">{{ $__('Plan new order') }}</h4>
                    <div class="row">
                        <div class="col-md-4 mb-1" v-for="item in waste_groups" :key="item.id">
                            <button type="button" class="btn btn-secondary btn-sm round waves-effect no-text-break"
                                    @click="openModal(item.id)"
                                    :style="{background: item.color, borderColor: 'lightgrey', opacity: 0.8}">
                                {{ item.name }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-auto">
                    <a href="javascript:void(0)" class="btn btn-success waves-effect float-right" @click="openModal"
                                   :style="{background: $env_color}">{{ $__('Plan') }}</a>
                </div>
            </div>
        </div>
    </div>
    <Dialog :header="$__('Add new order')" v-model:visible="displayModal" :style="{width: '550px'}" :modal="true"
            :closable="false" :closeOnEscape="false">
        <form method="POST">
            <div class="form-group">
                <label for="origin"
                       :class=" + v$.order.origin.$error ? 'form-label text-danger': 'form-label'">{{ $__('Origin') }}
                    {{ v$.order.origin.required ? '*' : '' }}
                    {{ v$.order.origin.$error ? ' - ' + $__('is required') : '' }}</label>
                <Dropdown @change="update"
                          id="origin" v-model="order.origin" :style="`--bcolor: ${$env_color};width: 100%`"
                          :options="addresses"
                          optionLabel="single_line" :placeholder="$__('Select disposal address')"/>
            </div>
            <div class="form-group">
                <label for="waste-groups"
                       :class=" + v$.order.waste_group.$error ? 'form-label text-danger': 'form-label'">{{ $__('Waste Group') }}
                    {{ v$.order.waste_group.required ? '*' : '' }}
                    {{ v$.order.waste_group.$error ? ' - ' + $__('is required') : '' }}</label>
                <Dropdown @change="update" :disabled="!order.origin"
                          id="waste-groups" v-model="order.waste_group" :style="`--bcolor: ${$env_color};width: 100%`"
                          :options="waste_groups"
                          optionLabel="name" :placeholder="$__('Select a waste group')"/>
            </div>
            <div class="form-group">
                <label for="eural-code" :class=" + v$.order.eural_code.$error ? 'form-label text-danger': 'form-label'">{{ $__('Eural Code') }}
                    {{ v$.order.eural_code.required ? '*' : '' }}
                    {{ v$.order.eural_code.$error ? ' - ' + $__('is required') : '' }}</label>
                <Dropdown @change="update"
                          :disabled="!order.waste_group"
                          id="eural-code" v-model="order.eural_code" :style="`--bcolor: ${$env_color};width: 100%`"
                          :options="eural_codes"
                          optionLabel="full_code" :placeholder="$__('Select eural code')"/>
            </div>
            <div class="form-group">
                <label for="weight"
                       :class=" + v$.order.planned_collection_kgs.$error ? 'form-label text-danger': 'form-label'">{{ $__('Estimated weight') }}
                    {{ v$.order.planned_collection_kgs.required ? '*' : '' }}
                    {{ v$.order.planned_collection_kgs.$error ? ' - ' + $__('is required') : '' }}</label>
                <div class="input-group input-group-merge form-password-toggle mb-2">
                    <input id="weight" class="form-control" type="number" v-model="order.planned_collection_kgs"
                           @focus="focus_group" @blur="blur_group" @keypress="isInt($event)"/>
                    <div class="input-group-append" v-if="order.waste_group && collections_history.length > 0">
            <span class="input-group-text cursor-pointer" :data-visible="true" @click.prevent="showHistory">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="feather feather-archive"><polyline points="21 8 21 21 3 21 3 8"></polyline><rect x="1" y="3"
                                                                                                           width="22"
                                                                                                           height="5"></rect><line
                  x1="10" y1="12" x2="14" y2="12"></line></svg>
            </span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="reference" class="form-label">{{ $__('Customer reference') }}</label>
                <input id="reference" class="form-control" type="text" v-model="order.customer_reference" @focus="focus"
                       @blur="blur"/>
            </div>
            <div class="form-group">
                <label for="note" class="form-label">{{ $__('Note to driver') }}</label>
                <textarea id="note" class="form-control" v-model="order.note_to_driver" @focus="focus"
                          @blur="blur"></textarea>
            </div>
            <div class="d-flex flex-wrap flex-column">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1"
                           v-model="order.call_customer_upfront" :style="`--bcolor: ${$env_color}`">
                    <label class="custom-control-label" for="customCheck1"
                           :style="`--bcolor: ${$env_color}`">{{ $__('Call upfront') }}</label>
                </div>
                <div class="custom-control custom-checkbox mt-1">
                    <input type="checkbox" class="custom-control-input" id="customCheck2" v-model="order.is_urgent"
                           :style="`--bcolor: ${$env_color}`">
                    <label class="custom-control-label" for="customCheck2"
                           :style="`--bcolor: ${$env_color}`">{{ $__('Urgent (extra costs can be applied)') }}</label>
                </div>
            </div>
        </form>
        <template #footer>
            <div class="d-flex align-content-center" style="flex-basis: max-content;">
                <Button :label="$__('Cancel')" icon="pi pi-times" @click="closeModal" class="p-button-danger"/>
                <Button :label="$__('Plan')" icon="pi pi-check" @click="handleSubmit" class="p-button-success ml-auto"
                        :style="{background: $env_color}"/>
            </div>
        </template>
    </Dialog>
    <Dialog :header="$__('History')" v-model:visible="displayBasic">
        <ul class="list-group" v-for="(collection_history, index) in collections_history" :key="index">
            <li class="list-group-item d-flex"
                @click.prevent="selectCollectionHistory(collection_history.collected_weight_kg)">
        <span class="mr-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-instagram font-medium-2"><rect x="2" y="2" width="20" height="20" rx="5"
                                                                     ry="5"></rect><path
              d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51"
                                                                               y2="6.5"></line></svg>
        </span>
                <ol>
                    <li>
                        {{ collection_history.driver.full_name }}
                    </li>
                    <li>
                        {{ collection_history.updated_at ? collection_history.updated_at : '-' }}
                    </li>
                    <li>
                        {{ collection_history.collected_weight_kg > 0 ? collection_history.collected_weight_kg : 0 }}
                    </li>
                </ol>
            </li>
        </ul>
        <template #footer>
            <Button :label="$__('Close')" icon="pi pi-times" @click="closeShowHistory" class="p-button-primary"
                    autofocus/>
        </template>
    </Dialog>
    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>{{confirmationText}}</span>
        </div>
        <template #footer>
            <Button label="OK" icon="pi pi-check" @click="closeConfirmation" class="p-button-text" autofocus />
        </template>
    </Dialog>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import agent from "@/agent";
import {envColor} from "../../mixins";
import {ref} from "vue";

export default {
    name: "CreateOrderCard",
    mixins: [envColor],
    components: {
        Button,
        Dialog,
        Dropdown,
    },
    setup() {
        const order = ref({
                waste_group: null,
                eural_code: null,
                planned_collection_kgs: null,
                customer_reference: null,
                note_to_driver: '',
                call_customer_upfront: false,
                is_urgent: false,
                disposer: null,
                invoice_debtor: null,
                origin: null,
                status: 1
            });
        return {v$: useVuelidate(), order}
    },
    props: {
        modelValue: {
            type: Object,
        },
    },
    data() {
        return {
            displayModal: false,
            displayBasic: false,
            displayConfirmation: false,
            confirmationText: '',
            message: "",
            loading: false,
            addresses: [],
            order_image: null,
            waste_groups: [],
        }
    },
    validations() {
        return {
            order: {
                waste_group: {required},
                eural_code: {required},
                planned_collection_kgs: {required},
                origin: {required},
            }
        }
    },
    watch: {
        'order.eural_code': {
            handler: function (newVal) {
                if (newVal) {
                    this.getCollectionHistory({
                        waste_group_id: this.order.waste_group.id,
                        origin_id: this.order.origin.id,
                        eural_code_id: newVal.id
                    })
                    this.order.planned_collection_kgs = null
                } else {
                    this.clearCollectionHistory()
                }
            },
            immediate: true
        },
        'order.waste_group': {
            handler: function (newVal) {
                let self = this;
                if (newVal) {
                    this.order.eural_code = null
                    let payload = {
                        relation_id: this.modelValue.id,
                        waste_group_id: newVal.id
                    }
                    this.getEuralCodesByWasteGroup(payload).then(function (data) {
                        if (data.length > 0) {
                            self.order.eural_code = data[0]
                            self.changeWasteGroupHandler()
                        }
                    });
                    this.order.planned_collection_kgs = null
                } else {
                    this.clearCollectionHistory()
                    this.order.eural_code = null
                }
            },
            immediate: true
        },
        'order.origin': {
            handler: function (newVal, oldVal) {
                if (newVal && oldVal && (newVal.id !== oldVal.id)) {
                    this.order.waste_group = null
                    this.order.eural_code = null
                    this.order.planned_collection_kgs = null
                }
            },
            immediate: true
        },
        collections_history: {
            handler: function (newVal) {
                if (newVal.length > 0) {
                    this.order.planned_collection_kgs = newVal[0].collected_weight_kg
                }
            },
            immediate: true
        },
        'user.lang': {
            handler: function (newVal) {
                if (newVal) {
                    this.getWasteGroups(this.modelValue.id).then(wasteGroups => {
                        this.waste_groups = []
                        wasteGroups.forEach(item => {
                            item.name = this.$__(item.name)
                            this.waste_groups.push(item)
                        })
                    })
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
        }),
        ...mapGetters({
            eural_codes: 'orders/euralCodes',
            collections_history: 'orders/collectionsHistory',
        })
    },
    created() {
        this.order_image = require(`@/assets/images/${process.env.VUE_APP_ENV_ORDER_IMAGE ? process.env.VUE_APP_ENV_ORDER_IMAGE: 'blank_user_avatar.png'}`)
        this.getWasteGroups(this.modelValue.id).then(wasteGroups => {
            this.waste_groups = []
            wasteGroups.forEach(item => {
                item.name = this.$__(item.name)
                this.waste_groups.push(item)
            })
        })
    },
    methods: {
        openModal(id=null) {
            this.order.disposer = this.modelValue
            this.order.planned_collection_kgs = null
            this.order.invoice_debtor = this.modelValue.invoice_debtor
            this.addresses = this.modelValue.addresses
            if (this.addresses.length === 1) {
                this.order.origin = this.addresses[0]
                if(id){
                    this.order.waste_group = this.waste_groups.find(wg => wg.id === id)
                }
            }
            this.displayModal = true;
        },
        closeModal() {
            this.order.waste_group = null
            this.order.eural_code = null
            this.order.planned_collection_kgs = null
            this.order.customer_reference = null
            this.order.note_to_driver = ''
            this.order.call_customer_upfront = false
            this.order.is_urgent = false
            this.order.disposer = null
            this.order.invoice_debtor = null
            this.order.origin = null
            this.order.status = 1
            this.displayModal = false;
            this.$forceUpdate()
            this.v$.$reset()
        },
        closeShowHistory() {
            this.displayBasic = false;
        },
        handleSubmit() {
            this.v$.$touch()
            if (!this.v$.$error) {
                this.loading = true;
                this.loading = false;
                agent.Orders.create(this.order).then(() => {
                    this.$toast.add({severity: 'success', summary: 'Success', detail: 'Order submitted', life: 3000});
                }).catch(error => {
                    if(error.response.status === 409) {
                        this.displayConfirmation = true
                        this.confirmationText = error.response.data.message
                    } else {
                        this.$toast.add({
                            severity: 'error',
                            summary: this.$__('Error'),
                            detail: this.$__('Please contact you account manager'),
                            life: 5000
                        });
                    }
                })
                this.closeModal()
            }
            return false
        },
        update() {
            this.$forceUpdate()
        },
        showHistory() {
            if (this.order.waste_group && this.collections_history.length > 0) {
                this.displayBasic = true;
            }
            return false
        },
        selectCollectionHistory(collected_weight_kg) {
            this.order.planned_collection_kgs = collected_weight_kg
            this.closeShowHistory()
        },
        ...mapActions({
            getWasteGroups: 'orders/getWasteGroups',
            getEuralCodesByWasteGroup: 'orders/getEuralCodesByWasteGroup',
            getCollectionHistory: 'orders/getCollectionHistory',
            clearCollectionHistory: 'orders/clearCollectionHistory',
        }),
        isInt: function (evt) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode >= 48 && charCode <= 57) {
                return true;
            } else {
                evt.preventDefault();
            }
        },
        closeConfirmation() {
            this.displayConfirmation = false;
            this.confirmationText = ''
        },
        changeWasteGroupHandler() {
            let data = {
                'disposer_id': this.order.disposer.id,
                'debtor_id': this.order.invoice_debtor ? this.order.invoice_debtor.id: '',
                'waste_group_id': this.order.waste_group.id,
                'origin_id': this.order.origin.id,
                'eural_code_id': this.order.eural_code.id,
            }
            agent.Relation.getCustomerReference(data).then(r => {
                this.order.customer_reference = r.data
            }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please contact you account manager',
                    life: 5000
                });
            })
        }
    },
    beforeUnmount() {
        this.order.waste_group = null
        this.order.eural_code = null
    },
}
</script>

<style scoped>
.inverted-bar {
    position: relative;
}

.inverted-bar:before {
    background-color: aqua;
    color: red;
    width: 100%;
}

.inverted-bar:after {
    background-color: red;
    color: aqua;
    width: 20%;
}

.inverted-bar:before,
.inverted-bar:after {
    padding: 10px 0;
    text-indent: 10px;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
}

.list-group > li {
    cursor: pointer;
}
.btn-secondary {
    color:#d5d5d5
}
</style>