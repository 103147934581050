<template>
  <template v-if="loggedIn">
    <TopNavbar :title="$__(this.$route.name)"></TopNavbar>
    <LeftNavbar></LeftNavbar>
  </template>
  <div class="app-content content">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper">
      <div class="content-header row">
      </div>
      <div class="content-body">
        <Toast position="top-center" />
        <router-view></router-view>
      </div>
    </div>
  </div>
  <footer id="footer" class="footer footer-static footer-light footer_content">
    <p class="clearfix mb-0"><span class="float-md-left d-block d-md-inline-block mt-25">© {{currentYear}}</span><span class="float-md-right d-none d-md-block">Hand-crafted &amp; Made with<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg> by <a href="https://sedna.software" target="_blank" :style="{color: $env_color}">SEDNA.software</a></span></p>
  </footer>
</template>

<script>
import LeftNavbar from "@/components/navbars/LeftNavbar";
import TopNavbar from "@/components/navbars/TopNavbar";
import Toast from 'primevue/toast';

const feather = require('feather-icons');

export default {
  name: 'App',
  components: {
    LeftNavbar,
    TopNavbar,
    Toast
  },
  watch: {

  },
  methods: {
    onClick(e){
        let app = document.getElementById('app');
        let menu = document.getElementById('mainMenu');
        if (menu && e.target !== menu && !menu.contains(e.target)) {
            if(app.classList.contains('menu-open') && !e.target.classList.contains('pi-bars')){
                app.classList.add("changing-menu");
                app.classList.remove("menu-open");
                app.classList.remove("changing-menu");
                app.classList.add("menu-hide");
            }
            if(app.classList.contains('menu-hide') && e.target.classList.contains('pi-bars')){
                app.classList.add("changing-menu");
                app.classList.remove("menu-hide");
                app.classList.remove("changing-menu");
                app.classList.add("menu-open");
            }


        }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    cssVars() {
      return {
        '--bg-color': process.env.VUE_APP_ENV_BUTTON_COLOR,
      }
    },
    currentYear(){
        return new Date().getFullYear()
    }
  },
  created(){
    if (this.loggedIn){
      this.$store.dispatch("auth/setUser");
    }
    if(process.env.VUE_APP_ENV_REDIRECT_URL){
        window.location.href = `${process.env.VUE_APP_ENV_REDIRECT_URL}${this.$route.path}`;
    }
  },
  mounted() {
    feather.replace()
    document.addEventListener('click', this.onClick)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.onClick)
  }
}
</script>

<style>

</style>
