<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <a href="" class="brand-logo">
                <img id="logo_img" v-cloak />
            </a>
            <div class="card mb-0">
                <div class="card-body" style="height: 200px; display: flex; justify-content: center; align-items: center;">
                    <loading :active="isLoading" :can-cancel="false" :is-full-page="false"></loading>
                    <h3 v-if="message" style="text-align: center">
                        {{message}}
                        <br/>
                        {{$__('Please try')}}
                        <router-link to="/login">
                            <span :style="{color: $env_color}">{{ $__('Login') }}</span>
                        </router-link>
                        {{$__('again.')}}
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {envColor, languageCheck} from "../mixins";
const feather = require('feather-icons')

export default {
    name: "Activate",
    components:{
        Loading
    },
    mixins: [envColor, languageCheck],
    data() {
        return {
            success: 0,
            message: "",
            uid: '',
            token: '',
            scope: '',
            email: '',
            isLoading: false,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/");
        } else {
            this.isLoading = true;
            const query = this.$route.query;
            if(query && query.token && query.uid && query.scope === 'portal'){
                this.$store.dispatch("auth/confirmEmail", query).then(
                    async () => {
                        this.isLoading = false;
                        await this.$store.dispatch("auth/setUser");
                        this.$router.push("/");
                    },
                    (error) => {
                        this.isLoading = false;
                        if(error.response && error.response.status === 403){
                            this.message = error.response.data.message;
                        }
                    }
                );
            }
        }
    },
    mounted () {
        feather.replace()
        document.body.classList.add('blank-page');
        const pathToBg = require(`@/assets/images/${this.$background_image}`)
        const pathToLogo = require(`@/assets/images/${this.$logo}`);
        document.body.style.backgroundImage = `url('${pathToBg}')`;
        document.body.style.backgroundRepeat = `no-repeat`;
        document.body.style.backgroundSize = `cover`;
        document.body.style.width = `100%`;
        document.body.style.top = `0`;
        document.body.style.left = `0`;
        let logo_img = document.getElementById('logo_img')
        logo_img.style.height = `100px`;
        logo_img.src = `${pathToLogo}`;
        let footer = document.getElementById('footer')
        footer.classList.add('footer_login')
    },
    unmounted () {
        document.body.classList.remove('blank-page');
        document.body.style.backgroundImage = 'none';
        let footer = document.getElementById('footer')
        footer.classList.remove('footer_login')
    },
    methods: {
    },
};
</script>

<style scoped>

</style>